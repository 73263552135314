@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
}

.sidebar_layout {
  width: 100%;
  display: flex;
  min-height: 100vh;
}

.active_link {
  text-decoration: underline #fff !important;
}

.profile_icon {
  list-style: none;
}

.list_side {
  background-color: #5A96E3;
  width: 15%;
  /* min-height: 100vh; */
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorMenu.css-n6v7qi-MuiPaper-root-MuiDrawer-paper {
  background: #5A96E3;
  ;
  color: #fff;
}

.list_side button {
  color: white;
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
}

.list_side ul {
  margin: 0;
  list-style: none;
  margin-top: 40px;
}

.list_side {
  background-color: #5A96E3;
  width: 15%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
}

.list_side ul li {
  /* margin-bottom: 15px; */
  padding: 10px 6px;
  transition: all .2s ease;

}

.editLink {
  margin-top: -26px;
}

ul.profile_icon li {
  padding: 10px 0px;

}

ul.profile_icon li a {
  max-height: 90px;

}

ul.profile_icon {
  max-height: 108px;
}

/* ul.profile_icon li:hover{
  background-color: #fff;
} */


/* ul.profile_icon li:hover a p svg {
  fill: #1976d2;
} */
/* ul.profile_icon li:hover button {
  color: #1976d2;
  background-color: transparent;
} */
/* ul.profile_icon li:hover{
  color: #1976d2;
  background-color: #fff;
} */
/* .list_side ul li:hover{
  background-color: #fff;
}
ul.icon_list_side li:hover a p svg {
    fill: #1976d2;
}
ul.icon_list_side li:hover a {
  color: #1976d2;
} */
.list_side ul li a {
  font-size: 19px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #fff;
  /* margin-bottom: 15px; */
  text-decoration: none;
  display: flex;
  gap: 10px;
  align-items: center;



}

.content_side {
  width: 85%;
  padding: 0 40px 0 40px;
}

.skelton p {
  color: #2e2929;
  ;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  line-height: 33px;
}

/* sidebar css */
.sidebar_layout .css-oqchhx-MuiContainer-root {
  padding-left: 0px;
  padding-right: 0px;
}

.tabs_content div#vertical-tabpanel-0 {
  width: 100%;
}

.tabs-data button {
  font-size: 20px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.home_page {
  margin: 50px 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.page_description {
  width: 50%;
}

.chatflow_use {
  text-align: end;
}

.page_description h1 {
  margin-bottom: 20px;
  font-size: 26px;
  color: #1976d2;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.skelton {
  margin-bottom: 100px;
}

.create_template button {
  font-size: 18px;
}

.create_template button:hover {
  border: 1px solid #1976d2 !important;
}

.create_template_btn a:hover {
  border: 1px solid #1976d2 !important;
  color: #1976d2;
  background-color: white;
}

.create_template_btn a {
  padding: 10px 25px;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  background-color: #1976d2;
  color: white;
  border-radius: 4px;
  font-weight: 600 !important;
}

.create_template button {
  color: white;
  background-color: #1976d2;
  padding: 10px 25px;
  border: 1px solid transparent;
  font-weight: 600 !important;
  /* 
  font-size: 18px;
  font-weight: 600;
  border: 1px solid transparent; 
  transition: all .2s ease;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  border-radius: 5px;
  white-space: nowrap;
   */
}

.create_template button:hover {
  border: 1px solid #1976d2;
  color: #1976d2;
  margin-bottom: 0;
}

.process_link {
  margin: 40px 0px;
}

.process_link a {
  color: white;
  background-color: #1976d2;
  padding: 12px 39px;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid #1976d2;
  transition: all .2s ease;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  border-radius: 5px;
}

.process_link a:hover {
  border: 1px solid #1976d2;
  background-color: transparent;
  color: #1976d2;
}

.icons {
  border-top: 1px solid #1976d2;
  padding: 20px;
}

.icons ul {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  gap: 40px;
  list-style: none;
  justify-content: space-between;
}

.icons ul li {
  display: flex;
  gap: 18px
}

.volume_list {
  width: 60%;
}

.chatflows_box {
  padding: 48px 150px;
}

.chatflows_box p {
  color: #1976d2;
  font-size: 18px;
  font-weight: 700;
}

.chatflows_page {
  margin: 50px 10px;
}

video:hover {
  border-bottom: 7px solid #1976d2;
  border-radius: 6px;
}

video {
  border-bottom: 7px solid transparent;
  border-radius: 6px;
}

/* chatflows css */

.chat_head h1 {
  font-size: 26px;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
  text-decoration: none;
  color: #1976d2;
  font-family: 'Poppins', sans-serif;
}

.flow_delete_btn {
  display: flex;
  justify-content: space-between;
  float: right;
}

.remove-userfield {
  background: #d32f2f !important;
}

.flow_delete_btn button {
  font-size: 18px;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #1976d2;
}

.flow_delete_btn button:hover {
  color: #1976d2;
  border: 1px solid #1976d2;
  background-color: transparent;
}

.flow_delete_btn .css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-top: 0;
}

.flow_delete_btn .MuiStack-root button {
  font-size: 18px;
  text-transform: capitalize;
}

.search_box {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.search_feild {
  width: 35%;
  border: 2px solid #1976d2;
  border-radius: 50px;
  padding: 3px;
}

.search_feild .MuiInputBase-root.MuiInputBase-colorPrimary {
  width: 100%;
}

.first_chatbox {
  margin-bottom: 11px;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-bottom: 8px solid green;
  height: 100%;
  min-height: 349px;
}

.first_chatbox1 {
  /* height: 100%!important; */
  margin-bottom: 11px;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-bottom: 8px solid #1976d2;
  min-height: 349px !important;
}

/* .TitleDes {
  max-height: 207px;
  overflow-y: scroll;
} */
.chat_heading p {
  margin-top: 80px;
}


.chat_boxes .css-1633um2-MuiPaper-root {
  border: 0;
  box-shadow: none;
  text-align: start;
}

.chat_link a {
  color: #000;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  transition: all 1s ease;
  width: 100%;
  /* word-break: break-all!important; */
}

.chat_link {
  max-width: 301px;
  /* word-break: break-all!important; */
}

.chat_heading .chat_link a {
  word-break: break-all !important;
}

.chat_link a:hover {
  text-decoration: underline;
}

.create_template_btn {
  margin: 50px 0px;
  text-align: center;
  font-weight: 600px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.css-1ujsas3 {
  font-family: Poppins !important;
}

/* .create_template_btn a{
    padding: 10px 30px;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
    color: #1976d2;
    border-radius: 4px;
    font-weight: 600px;
  } */

.flow_delete_btn a {
  padding: 12px 30px;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  background-color: #1976d2;
  color: #fff;
  border-radius: 4px;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #1976d2;
}

.flow_delete_btn a:hover {
  background-color: transparent;
  color: #1976d2;
  border: 1px solid #1976d2;
}

.chat_heading h6 {
  font-size: 16px;
  margin-top: 5px;
  font-weight: 500;
  color: #2e2929;
  font-family: 'Poppins', sans-serif;
  word-wrap: break-word;
}

/* create template modal css */
.modal_head {
  font-weight: 600;
}

.label_input {
  display: flex;
  gap: 40px;
  margin-bottom: 20px;
}

.label_input input {
  /* min-width: 589px; */
  /* min-width: 50%; */
  min-width: 76.8%;
  border: 1px solid #1976d2 !important;
  padding: 13px;
  font-size: 14px;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
}

.label_input label {
  min-width: 120px;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}

.label_input1 label {
  /* padding-top: 22px;
  min-width: 180px; */
    width: 30%;
    /* padding-top: 22px; */
    padding-top: 15px;
    padding-right: 10px;
}

.label_input textarea {
  min-height: 100px;
  min-width: 616px;
  border: 1px solid #1976d2;
  padding: 13px;
  font-size: 16px;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
}

/* chat flows page */
.chat_flows {
  margin: 50px 10px;
}

.chat_haed h1 {
  font-size: 26px;
  margin-bottom: 20px;
  color: #1976d2;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.chat_flow_delete_btn {
  display: flex;
  justify-content: end;
  margin-right: 120px;
}

.chat_flow_delete_btn .MuiStack-root button {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
}

.profile_icon li p svg {
  font-size: 28px;
  margin-left: 12px !important;
}

.system_prompt {
  margin-bottom: 20px;
}

.chat_flow_delete_btn a {
  font-size: 18px;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #1976d2;

}

.chat_flow_delete_btn a:hover {
  background-color: transparent;
  color: #1976d2;
  border: 1px solid #1976d2;
}

.chat_flow_delete_btn button {
  font-size: 18px;
  text-transform: capitalize;
}

.chat_label_input {
  /* display: flex; */
  margin-bottom: 20px;
  /* width: 20px; */
  word-wrap: break-word;
}

.chat_label_input input {
  min-width: 584px;
  border: 1px solid #1976d2;
  padding: 13px;
  font-size: 16px;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
}

.promt_input1 input {
  background-color: #f3f3f3;
}

.chat_label_input label {
  min-width: 200px;
  font-weight: 600;
  font-size: 20px;
  color: #000;
  padding-top: 0;
  font-family: 'Poppins', sans-serif;
}

.promt_label {
  min-width: 200px;
}

.chat_label_input textarea {
  min-height: 60px;
  min-width: 557px;
  max-width: 557px;
  border: 1px solid #1976d2;
  padding: 13px;
  font-size: 16px;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
}

.chat_label_input p {
  min-width: 120px;
  font-weight: 500;
  font-size: 18px;
  color: #2e2929;
  ;
}

.update_prompt_temp {
  padding: 5px 0 5px 0px !important;
  /* width: 0; */
}

.para_box {
  padding: 10px;
  width: 100%;
  overflow-x: auto;
  white-space: pre-wrap;
}

.collapse {
  text-align: right;
  cursor: pointer;
  /* border: 1px solid #dedede;
  margin-left: 917px;
  width: 27px;
  background: white;
  height: 22px;
  margin-top: 5px; */
}

/* end chat flows page */
/* start financial reviws */
.financial_reviews {
  margin: 50px 10px;
}

.chat_label_input1 {
  /* display: flex; */
  align-items: center;
  /* gap:10px; */
  margin-bottom: 30px;
}

.hand-cursor-icon {
  cursor: pointer;
}

.chat_label_input1 label {
  font-weight: 600;
  font-size: 20px;
  color: #000;
  font-family: 'Poppins', sans-serif;
}

.chat_label_input1 p {
  margin-top: 10px;
}

.provide_analise h5 {
  color: #000;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}

.analyse_box {
  padding: 10px 0 20px 0;
  border: 2px solid #1976d2;
  margin-bottom: 20px;
  border-radius: 4px;
  min-height: 100px;
}

.analyse_box span {
  color: #000;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

.process_temp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.process_temp1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-right: 1030px; */
}

/* .create_template {
  margin-bottom: 14px;
} */
.revenu_info ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
  margin-top: 20px;
}

.revenu_info ul li {
  color: #2e2929;
  ;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

/* .create_template button {
  color: #1976d2;
  background-color: white;
   border: none;
} */
.create_template button:hover {
  border: none;
  color: #1976d2;
}

/* start quartely_review_page */
.quartely_review_page {
  margin: 50px 10px;
}

.quartely_head h1 {
  margin-bottom: 20px;
  font-size: 26px;
  color: #1976d2;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.language_modal {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  align-items: baseline;
}

.slider_box.slider_box1>.MuiBox-root>span.MuiSlider-root.MuiSlider-marked {
  margin-bottom: 0;
}

.language_dropdown {
  width: 56%;
  display: flex;
  gap: 40px;
  align-items: center;
}

.language_head h3 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  white-space: nowrap;
}

.language_dropdown_info .MuiFormControl-root {
  min-width: 300px;
}

.language_slider {
  display: flex;
  gap: 40px;
  align-items: center;
  width: 60%;
}

.system_analyse {
  border: 2px solid #1976d2;
  border-radius: 5px;
  padding: 40px;
  max-height: 153px;
  overflow-y: auto;
  flex: 1;

}

.system_analyse1 {
  border: 2px solid #1976d2;
  border-radius: 5px;
  max-height: 153px;
  overflow-y: scroll;
}

.system_analyse1 textarea {
  border: transparent;
  width: 100%;
  min-height: 80px;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  max-width: 100%;
}

.para_icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.handleCopy {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.drawer-handle {
  padding: 35px 0 0 0;
}

/* .system_template_div{
  text-decoration: none !important;
} */
.system_prompt_temp {
  padding: 10px 12px;
}

.cipboard_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.para_icons.completed {
  fill: #18aa5d !important;
}

.para_icons.pending {
  fill: #cfc91d !important;
}

.system_analyse p {
  font-size: 18px;
  color: #2e2929;
  ;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
}

.job_data a {
  color: #1976d2;
  font-size: 18px;
}

.language_head1 h3 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.system_prompt {
  padding-bottom: 30px;
  border-bottom: 2px solid #8080804f;
}

.job_data {
  display: flex;
  gap: 20px;
}

.prompt_quartely {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

}

.promt_head h2 {
  margin-top: 30px;
  color: #000;
  font-size: 24px;
  font-weight: 600;

}

.promt_head {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.promt_head h2 span svg {
  fill: #1976d2;
  font-size: 30px;
}

.quarterly_buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.delete_btn button {
  font-size: 18px;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
}

.save_cancel_btn button {
  font-size: 18px;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #1976d2;
}

.save_cancel_btn button:hover {
  background-color: transparent;
  border: 1px solid #1976d2;
  color: #1976d2;
}

.save_cancel_btn a {
  font-size: 18px;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #1976d2;

}

.save_cancel_btn a:hover {
  background-color: transparent;
  border: 1px solid #1976d2;
  color: #1976d2;
}

/* end financial reviws */
.promt_head .MuiBox-root.css-1wnsr1i {
  width: 800px;
}

.language_slider1 {
  display: flex;
}

.prompt_select .MuiInputBase-root {
  width: 555px;
  border: 1px solid #1976d2;
  font-family: 'Poppins', sans-serif;
}

#app div {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.check_data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
}

.check_data a {
  margin: 0;
  padding: 0;
}

.check_data a svg {
  fill: #1976d2;
}

button#basic-button {
  text-transform: capitalize;
}

.css-q0jhri-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid transparent;
}

.css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-left: 0px;
}

.css-q0jhri-MuiInputBase-root-MuiInput-root:before {
  display: none;
}

.css-q0jhri-MuiInputBase-root-MuiInput-root:after {
  display: none;
}

textarea:focus,
input:focus {
  outline: 0;
}

.system_prompt1 {
  border-bottom: 0;
}

::-webkit-input-placeholder {
  font-family: 'Poppins', sans-serif;
  color: #bbbbbb;
  /* font-weight:600; */
}

.search_input .css-1orzy9s {
  border: 2px solid #1976d2;
  border-radius: 30px;
  padding: 5px 10px;
  border-radius: 50px;
  width: 400px;
}

.search_input input.MuiInputBase-input {
  width: 100%;
  padding-left: 50px;

}

.search_input {
  text-align: start;

}

em {
  font-family: 'Poppins';
}

.list_side ul li a p {
  margin-top: 10px;
}

.search_input .css-1iz4iln {
  top: 1px;
}

.prompt_select .MuiFormControl-root {
  margin: 0;
}

.chatflows_box div {
  /* width:auto !important; */
  /* height:auto !important; */
  width: 520px !important;
  height: 333px !important;
}

.chatflows_box div div {
  width: 100% !important;
  height: 100% !important;
}

ul.profile_icon {
  border-top: 1px solid #fff;
  /* padding-top: 10px; */
}

.light-blue {
  background-color: #1976d217;
  padding: 40px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.create_template button svg {
  margin-left: 11px;
}

.create_template2 button {
  text-decoration: underline;
}

.search_input .MuiInputBase-root.MuiInputBase-colorPrimary.css-7mt4h1-MuiInputBase-root {
  width: 100%;
}

.promt_input1 input::placeholder {
  opacity: 1;
}

#basic-menu {
  margin-left: 150px;

}

.promt_input::before {
  border: transparent;
}

.chat_label_input::before {
  border: transparent;
}

.chat_label_input::after {
  border: transparent;
}

.form_feilds {
  width: 60%;
}

.form_feilds1 {
  width: 100%;
}

.search_feild svg {
  fill: #1976d2;
}

.slider_box span.MuiSlider-markLabel {
  position: relative;
  top: -31px;
}

.language_slider {
  margin-top: 15px;
}

button#basic-button svg {
  width: 24px;
}

.system_analyse textarea {
  width: 100%;
  max-width: 100%;
  min-height: 36px;
  font-family: 'Poppins';
  padding: 26px;
}

.logout:empty {
  display: none;
}

a.profile-link button {
  padding: 0;
}

a.profile-link button div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

a.profile-link button {
  width: 100%;
}

a.profile-link {
  text-decoration: none;
  gap: 0 !important;
  display: flex;
}


.chat_link {
  align-items: center;
  display: flex;
  max-width: fit-content;
}

.chat_link a.link-with-icon {
  flex: auto;
  width: 80%;
  max-width: fit-content;
  word-break: break-all !important;
}

.editLink {
  width: 20%;
  margin: 0;
}

/* .chat_heading .chat_link a{
  word-break: break-all!important;
} */







/* responsive */
@media only screen and (width:1180px) and (height: 820px) {
  button#basic-button {


    color: white;
  }

  span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
    color: white;
  }

  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    fill: white;
  }

  .profile_icon li p svg {
    font-size: 36px;
    margin-left: 10px;
  }

  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    fill: #fff !important;
  }

  p.arrow-icon {
    transform: translate(10px, -21px);
  }

  p.arrow-icon {
    padding-top: 8px;
  }
}

@media only screen and (width:820px) and (height: 1180px) {
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    fill: #fff !important;
  }
}

@media only screen and (width:1920px) and (height: 1080px) {
  .editLink {
    transform: translate(-1014px, 10px) !important;
  }

  .profile-icon span.name {
    font-size: 16px;
  }
}

@media all and (min-width:1200px) and (max-width:1400px) {
  .language_modal {
    display: block;
  }







  ul.profile_icon p {
    margin-top: 9px !important;
  }

  ul.profile_icon {
    border-top: none;
  }

  .language_slider {
    margin-top: 15px;
  }

  .language_dropdown {
    width: 80%;
  }

  /* .chat_label_input textarea {
  min-height: 60px;
  min-width: 558px;
} */
}

@media only screen and (min-width: 1025px) {
  .menu_list {
    display: none;
  }

  .chat_label_input input {
    min-width: 527px;
  }

  ul.profile_icon {
    border-top: none;
  }

  ul.profile_icon p {
    margin-top: 9px;
  }

  a.profile-link {
    padding-left: 20pxs;
  }
}

@media all and (min-width:1025px) and (max-width:1199px) {
  .language_modal {
    display: block;
  }

  .language_slider {
    margin-top: 15px;
  }

  .language_dropdown {
    width: 80%;
  }

  .search_input .MuiInputBase-input {
    width: 364px;
  }

  .page_description {
    width: 50%;
  }

  .chatflow_use {
    width: 50%;
  }

  .list_side ul li a {
    font-size: 15px;
  }

  .chatflows_box {
    padding: 43px 10px;
  }

  .chat_label_input input {
    margin-left: 0;
    min-width: 466px;
  }

  .chat_label_input textarea {
    margin-left: 0;
    min-width: 498px;
    max-width: 498px;

  }

  .list_side button {
    font-size: 15px;
  }

  .list_side {
    width: 20%;
  }

  .content_side {
    padding: 0 40px;
    width: 80%;
  }

  #basic-menu .MuiList-root .MuiList-padding .MuiMenu-list {
    margin-left: 25px;
    margin-top: 305px;
  }

  .form_feilds {
    width: 100%;
  }

}

@media all and (width:1024px) and (height:803px) {
  .hr.MuiDivider-root.MuiDivider-fullWidth.css-39bbo6 {
    margin-top: 424px;
  }

  .icon .css-vubbuv {
    fill: grey !important;
  }

  .icons .css-vubbuv {
    fill: grey !important;
  }
}

@media all and (min-width:768px) and (max-width:1024px) {
  .page_description {
    width: 50%;
  }

  .editLink .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    fill: #1976d2 !important;
  }

  .css-vubbuv {
    fill: #fff !important
  }

  /* .css-1hskriy{
  height: 900px;
} */
  .user-profile {
    padding-bottom: 10px;
  }

  ul.profile_icon {
    margin-bottom: 133px;
  }

  .profile_icon li p svg {
    margin-left: 20px;
  }

  button#basic-button {
    margin-left: 11px !important;
    color: white;
  }

  .profile-icon span.name {
    font-size: 16px;
  }

  .css-cveggr-MuiListItemIcon-root {
    fill: white !important;
  }

  ul.profile_icon p {
    margin-top: 9px !important;
  }

  /* .css-g3hgs1-MuiBackdrop-root-MuiModal-backdrop{
  background-color: #fff;
  color: #1976d2;
  fill: #1976d2;
} */
  ul.profile_icon {
    border-top: none;
  }

  .chatflow_use {
    width: 50%;
  }

  .create_template button {
    padding: 7px 14px;
  }

  .chat_label_input input {
    min-width: 359px;
  }

  .chat_link a {
    font-size: 16px;
  }

  .first_chatbox {
    padding: 20px 12px;
    min-height: 284px;
  }

  .search_input .MuiInputBase-input {
    width: 549px;
  }

  .language_modal {
    display: block;
  }

  .language_dropdown {
    width: 100%;
    display: flex
  }

  .language_head h3 {
    font-size: 18px;
  }

  .language_head1 h3 {
    font-size: 18px;
  }

  .system_analyse {
    padding: 10px;
  }

  .prompt_select .MuiInputBase-root {
    width: 468px;
  }

  .MuiBox-root.css-1gy0ih7 {
    width: 508px;
  }

  .label_input input {
    min-width: 248px;
  }

  .label_input textarea {
    min-width: 275px;
  }

  .list_side ul {
    display: none;
  }

  .list_side ul {
    display: none;
  }

  .sidebar_layout {
    position: relative;
  }

  .content_side {
    width: 100%;
  }

  .search_input {
    width: 50%;
  }

  .chatflows_box {
    padding: 43px 10px;
  }

  .slider_box .MuiSlider-root {
    width: 87%;
  }

  .chat_label_input textarea {
    min-width: 387px;
    max-width: 387px;
  }

  .list_side {
    position: absolute;
    height: 39px;
    width: 62px !important;
    background-color: #5A96E3;
    padding: 0px 0px !important;
    border-radius: 3px;
    right: 10px;
    top: 10px;
  }

  .search_feild {
    width: 50%;
  }

  .form_feilds {
    width: 100%;
  }

  /* .slider_box {
  padding-left: 22px;
} */
  .slider_box1 {
    padding-left: 39px;
    margin-top: 10px;
  }
}


/* @media all and (min-width:820px) and (max-width:1080px){

  button#basic-button {
    margin-top: -36px!important;
    margin-left: -1px!important;
  }
  } */


@media all and (min-width:320px) and (max-width:767px) {
  .editLink {
    transform: translate(-2px, 15px) !important;
  }

  .css-1ontqvh {
    margin-top: 30px;
  }

  .editLink .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    fill: #1976d2;
    width: auto;
    margin-top: -33px;
  }

  /* .css-1hskriy{
  height: 900px;
} */

  .user-profile {
    padding-bottom: 10px;
  }

  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    font-size: 28px;
  }

  ul.profile_icon li {
    padding: 10px 0;

  }

  .profile-icon span.name {
    font-size: 16px;
  }

  span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
    color: white;
  }

  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    fill: white;
  }

  .profile_icon li p svg {
    font-size: 36px;
    margin-left: 10px;
  }

  .home_page {
    display: block;
  }

  .css-cveggr-MuiListItemIcon-root {
    fill: white !important;
  }

  .content_side {
    padding: 0 0px 0 0px;
  }

  ul.profile_icon {
    border-top: none;
  }

  ul.profile_icon p {
    margin-top: 9px !important;
  }

  .page_description {
    width: 100%;
    margin-top: 60px;
    border-top: 1px solid #e2e2e2;
    padding: 10px;
  }

  .create_template button {
    padding: 12px 20px;
    font-size: 14px;
    margin-bottom: 15px;
    width: 100%;
  }

  .process_link a {
    padding: 12px 20px;
    font-size: 14px;
    display: block;
    width: 100%;
    text-align: center;


  }

  .chatflow_use {
    width: 100%;
  }

  .chat_head h1 {
    font-size: 26px;
  }

  /* .chat_head{
  color: green;
} */
  .css-1gy0ih7 {
    width: 300px !important;
    ;
  }

  .label_input {
    display: block;
  }

  .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .page_description h1 {
    font-size: 26px;
    padding-top: 20px;
  }

  .skelton p {
    /* width: 468px; */
    font-size: 16px;
  }

  .skelton {
    margin-bottom: 32px;
  }

  .process_link {
    margin: 20px 0px;
  }

  .label_input textarea {
    min-width: 100%;
    max-width: 100%;
  }

  .chat_label_input {
    display: block;
  }

  .chat_label_input input {
    min-width: 197px;
    margin-left: 0;
  }

  /*   */

  .search_input .MuiInputBase-input {
    width: unset;
  }

  .chat_haed h1 {
    font-size: 26px;
  }

  .quartely_head h1 {
    font-size: 26px;
  }

  .language_modal {
    display: block;
  }

  .language_dropdown {
    display: block;
    width: 100%;
  }

  .language_dropdown {
    margin-bottom: 15px;
  }

  .language_head h3 {
    font-size: 16px;
  }

  .language_slider {
    display: block;
    width: 100%;
  }

  .slider_box>.MuiBox-root {
    width: 100% !important;
  }

  .slider_box span.MuiSlider-markLabel[data-index="3"] {
    left: 90% !important;
  }

  .language_head1 h3 {
    font-size: 18px;
  }

  .system_analyse {
    padding: 15px;
  }

  .system_analyse p {
    font-size: 16px;
  }

  .job_data a {
    font-size: 16px;
  }

  .promt_head h2 {
    font-size: 18px;
  }

  .prompt_quartely {
    display: block;
  }

  .job_data {
    margin-bottom: 10px;
  }

  .quarterly_buttons {
    display: block;
  }

  .delete_btn {
    margin-bottom: 10px;
  }

  .prompt_select .MuiInputBase-root {
    width: 272px;
  }

  .flow_delete_btn .MuiStack-root button {
    padding: 10px;
  }

  /* .create_template {
  margin-bottom: 0px;
} */
  /* .slider_box .css-1v5z18m {
  width: 268px;
} */
  .language_dropdown_info .MuiFormControl-root {
    min-width: 100%;
    margin: 0;
  }

  .list_side ul {
    display: none;
  }

  .sidebar_layout {
    position: relative;
  }

  .content_side {
    width: 100%;
  }

  .list_side {
    position: absolute;
    height: 39px;
    width: 62px !important;
    background-color: #5A96E3;
    padding: 0px 0px !important;
    border-radius: 3px;
    right: 10px;
    top: 10px;
  }

  .flow_delete_btn {
    justify-content: end;
  }

  .search_input .css-1orzy9s {
    width: 100%;
  }

  .flow_delete_btn a {
    font-size: 18px;
    white-space: nowrap;
    font-family: 'Poppins', sans-serif;
  }

  /* .flow_delete_btn a:hover{
  background-color: transparent;
  border: 1px solid #1976d2;
  color: #1976d2;
} */
  .language_dropdown .MuiFormControl-root.css-1869usk-MuiFormControl-root {
    margin: 7px 0px 0px 0px;
  }

  .prompt_select .MuiFormControl-root {
    margin: 0;
  }

  .prompt_select .MuiInputBase-root {
    width: 100%;
  }

  .chat_label_input input {
    width: 100%;
    margin-left: 0;
  }

  .chat_label_input .MuiInputBase-root.MuiInput-root {
    width: 100%;
  }

  .prompt_select .MuiFormControl-root {
    width: 100%;
  }

  .label_input input {
    min-width: 200px;
  }

  .chat_label_input label {
    padding-top: 12px;
    font-size: 17px;
    display: block;
    width: 100%;
  }

  .home_page {
    margin: 0;
  }

  .chatflows_box {
    padding: 0px 10px;
  }

  .light-blue {
    padding: 11px;
  }

  .first_chatbox {
    min-height: 234px;
  }

  .chatflows_page {
    margin: 70px 0px;
    padding: 20px 10px;
    border-top: 1px solid #e2e2e2;
  }

  .search_box {
    display: block;
  }

  .search_feild {
    width: 100%;
    margin-bottom: 20px;
  }

  .form_feilds {
    width: 100%;
  }

  .chat_label_input textarea {
    min-width: 100%;
    max-width: 100%;
  }

  .slider_box {
    padding-left: 2px;
    margin-top: 10px;
  }

  .chat_flows,
  .quartely_review_page {
    margin: 70px 0px;
    padding: 20px 10px;
    border-top: 1px solid #e2e2e2;
  }

  /* video {
  margin: 30px;
  width: 500px;
  max-width: 84%;
  position: relative;
  left: -33px;
} */
}


/* updated css */

/* Apply styles only on mobile devices */
@media(min-width: 320px) and (max-width: 767px) {
  .quartely_review_page {
    overflow-x: hidden !important;
  }

  .editLink .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    fill: #1976d2;
    width: auto;
    margin-top: -33px;
  }

  .icon .css-vubbuv {
    fill: grey !important;
  }

  .css-1ontqvh {
    margin-top: 30px;
  }

  /* .css-1hskriy{
    height: 900px;
  } */
  .user-profile {
    padding-bottom: 10px;
  }

  ul.profile_icon li {
    padding: 10px 0;

  }

  span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
    color: white;
  }

  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    fill: white;
  }

  .profile_icon li p svg {
    font-size: 36px;
    margin-left: 10px;
  }

  .editLink {
    transform: translate(-2px, 15px) !important;
  }

  .profile-icon span.name {
    font-size: 16px;
  }

  ul.profile_icon {
    border-top: none;
  }

  .css-i4bv87-MuiSvgIcon-root {
    fill: white !important;
  }

  button#basic-button {


    color: white !important;
  }

  ul.profile_icon p {
    margin-top: 9px !important;
  }
}

.logout {
  margin-left: -70px;
}

/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  top: 359px!important;
  left: -59px!important;
} */
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  height: 28px;
  margin-left: -3px;
  align-items: center;
  margin-top: -9px;
}

/* .profile-icon{
  margin-left: -14px;
}
.profile-name{
  margin-left: -9px;
  margin-top: 5px;
}
.arrow-icon{
  margin-left: 10px;
} */


.icon_list_side li {
  list-style: none;
  margin-bottom: 10px;
  /* Add spacing between list items */
}

.icon_list_side li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  padding: 10px;
  padding-left: 31px;
  transition: background-color 0.3s, color 0.3s;

  margin-bottom: -27px;
}

.icon_list_side li a.selected {
  background-color: white;
  color: #1976d2 !important;
  width: 100%;

  padding-left: 31px;
}

.icon_list_side li a.selected p svg {
  fill: #1976d2;
}

.para_box pre {
  padding: 10px;
  overflow: auto;
  white-space: pre-wrap;
  border: 1px solid #1976d2 !important;
  border-radius: 5px;
  background: white;
  margin-left: -10px;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input.Mui-disabled {
  -webkit-text-fill-color: #000000 !important;
}



/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
  left: -70px!important;
} */
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper .selectMenu {
  transform: translate(577px, 10px) !important;
}

.quartely_head {
  margin-bottom: 40px;
}

/* .editLink{
  transform: translate(-733px, 10px);
} */
.prompt_select .MuiInputBase-root {
  border: none;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root.Mui-disabled:before {
  border-bottom-style: none !important;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
}

.css-q0jhri-MuiInputBase-root-MuiInput-root {
  border-bottom: none !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input.Mui-disabled {
  background-color: white !important;
}

.MuiBox-root.css-1hskriy>ul,
.MuiBox-root.css-1hskriy>hr,
.MuiBox-root.css-1hskriy>div {
  width: 100%;
}

.MuiBox-root.css-1hskriy>div {
  align-self: flex-end;
}

@media (min-width: 390px) and (max-width: 844px) {

  hr.MuiDivider-root.MuiDivider-fullWidth.css-39bbo6 {
    margin-top: 485px;
  }

  .chat_heading .chat_link a {
    word-wrap: break-word !important;
  }

  .editLink .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    fill: #1976d2;
    width: auto;
    margin-top: -33px;
  }

  .icon .css-vubbuv {
    fill: grey !important;
  }

  /* .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.cat-select {
    width: 337px!important;
} */
  .flow_delete_btn {
    margin-top: 20px;
    margin-right: 10px;
  }

  .tab {
    margin-top: 85px;
  }

  hr.MuiDivider-root.MuiDivider-fullWidth.css-39bbo6 {
    margin-top: 485px;
  }

  .chat_heading .chat_link a {
    word-wrap: break-word !important;
  }

  .editLink .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    fill: #1976d2;
    width: auto;
    margin-top: -33px;
  }

  .icon .css-vubbuv {
    fill: grey !important;
  }

  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-animated.cat-heads {
    margin-right: -300px;
    color: #000;
    margin-left: 19px !important;
    margin-bottom: 10px;
  }

  /* label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-animated.cat-heads1{  margin-right: -300px;
  color: #000;
  margin-left: 19px!important;
  margin-bottom: 10px;
} */
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-animated.cat-heads1 {
    margin-top: 72px;
    margin-left: -505px !important;
    width: 450px !important;
  }

  /* label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-animated.cat-heads {
  margin-top: 72px;
  margin-left: -505px!important;
  width: 450px!important;
} */
  .search_feild.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.cat-select {
    border: 2px solid #1976d2;
    width: 352px !important;
    /* margin-top: 169px; */
    margin-right: 112px;
    margin-left: 122px;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.cat-select.select {
    border: 2px solid #1976d2;
    width: 352px !important;
    margin-top: 174px;
    /* margin-right: 112px; */
    margin-left: -228px;
  }

  .chat_head.head {
    margin-bottom: -72px;
  }

  .serachBar {
    width: 353px !important;
  }

  .search_feild svg {
    fill: #1976d2 !important;
    margin-top: 0px !important;
  }

  /* .chat_head {
  margin-bottom: -76px;
} */
  .chat_label_input textarea {
    /* min-width: 229px; */
    max-width: 100%;
  }

  .chat_label_input {
    width: 359px;
  }

  .chat_flow_delete_btn {
    margin-right: 14px;
  }

  .chat_label_input {
    width: 350px !important;
  }

  .Button-modal .MuiBox-root {
    width: 280px !important;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.user-select {
    width: 217px;
  }

  .create_template2 .MuiSvgIcon-root {
    fill: #1976d2 !important;
  }

  .chat_link a.link-with-icon {
    word-break: break-all !important;
  }

  .label_input textarea {
    max-width: 225px;
  }

  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-animated.cat-heads1 {
    margin-top: 72px;
    margin-left: -505px !important;
    width: 450px !important;
  }
}

@media (min-width: 375px) and (max-width: 667px) {
  .editLink {
    transform: translate(-2px, 0px);
  }

  .chat_heading .chat_link a {
    word-wrap: break-word !important;
  }

  hr.MuiDivider-root.MuiDivider-fullWidth.css-39bbo6 {
    margin-top: 308px;
  }

  .icon .css-vubbuv {
    fill: grey !important;
  }

  .editLink .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    fill: #1976d2;
    width: auto;
    margin-top: -33px;
  }

  .css-1ontqvh {
    margin-top: 30px;
  }

  /* .css-1hskriy{
  height: 900px;
} */
  .user-profile {
    padding-bottom: 10px;
  }

  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    font-size: 28px;
  }

  span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
    color: white;
  }

  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    fill: white;
  }

  .profile_icon li p svg {
    font-size: 36px;
    margin-left: 10px;
  }

  hr.MuiDivider-root.MuiDivider-fullWidth.css-9mgopn-MuiDivider-root {
    margin-top: 360px !important;
  }

  .profile-icon span.name {
    font-size: 16px;
  }

  ul.profile_icon {
    margin-left: 14px;
    margin-top: 14px;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorMenu {
    background: #5A96E3;
    color: #fff;
    overflow-y: hidden;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.cat-select {
    border: 2px solid #1976d2;
    width: 337px !important;
  }

  .flow_delete_btn {
    margin-top: 20px;
    margin-right: 10px;
  }

  .tab {
    margin-top: 85px;
  }

  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-animated.cat-heads {
    margin-right: -300px;
    color: #000;
    margin-left: 10px !important;
    margin-bottom: 10px;
  }

  .chat_label_input textarea {
    /* min-width: 229px; */
    max-width: 100%;
  }

  .chat_label_input {
    width: 329px !important;
  }

  .chat_flow_delete_btn {
    margin-right: 14px;
  }

  .chat_flows,
  .quartely_review_page {
    margin: 70px 10px 10px 10px;
  }

  .create_template2 .MuiSvgIcon-root {
    fill: #1976d2 !important;
  }

  .Button-modal .MuiBox-root {
    width: 280px !important;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.user-select {
    border: 1px solid #1976d2;
    width: 227px;
  }

  .chat_link a.link-with-icon {
    word-break: break-all !important;
  }

  .label_input textarea {
    max-width: 225px;
  }
}

@media screen and (min-width: 1440px) {
  .list_side ul li {
    padding: 10px 0px !important;
  }

  ul.profile_icon {
    border-top: none;
  }

}


div:where(.swal2-container) button:where(.swal2-styled) {
  transition: none !important;
}

.edit-icon {
  fill: grey !important;
  border: none;
  transition: background-color 0.3s;
}

.edit {
  background-color: white !important;
  margin-left: -39px;
  margin-top: 5px;
}

.edit button:hover {
  /* background-color: white!important; */
  border: none;
}


/* .label_input input:hover{
  border-bottom: 1px solid #1976d2!important;
} */

.css-1hskriy {
  width: 250px;
  background-color: #5a96e3;
  color: white;
}

.css-vubbuv {
  fill: white;
}


.css-1nbw9tx::before {
  border-bottom: #1976d2 !important;
}

.css-1nbw9tx::after {
  border-bottom: #1976d2 !important;
}

/* .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.css-1nbw9tx:hover, .label_input input:hover {
border-bottom:none!important;
} */
.css-1nbw9tx::after {
  border-bottom: #1976d2 !important;
}

.css-1nbw9tx::before {
  border-bottom: #1976d2 !important;
}

/* .css-1nbw9tx.Mui-disabled::before {
  border-bottom: 1px solid #1976d2;
} */

.css-mnn31.Mui-disabled {
  -webkit-text-fill-color: black;
}

.css-1nbw9tx.Mui-disabled::before {
  border-bottom-style: none;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.css-1nbw9tx::before {
  border-bottom: #1976d2 !important;
}

.css-1nbw9tx::after {
  border-bottom: none !important;
}

.css-1nbw9tx:hover:not(.Mui-disabled, .Mui-error)::before {
  border-bottom: #1976d2 !important;
}

/* .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.css-1nbw9tx:hover, .label_input input:hover {
  border-bottom:#1976d2!important;
} */
.css-1nbw9tx.Mui-disabled::before {
  border-bottom-style: none !important;
}

.css-mnn31.Mui-disabled {
  -webkit-text-fill-color: black !important;
}

.flow_delete_btn button {
  background-color: #1976d2;
  border: 1px solid #1976d2;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  padding: 12px 30px;
  text-decoration: none;
  text-transform: capitalize;
}

.flow_delete_btn button:hover {
  color: #1976d2;
  border: 1px solid #1976d2;
  background-color: transparent;
}

.css-mnn31.Mui-disabled {
  background-color: #fff !important;
}

/* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
  font-size: 25px;
} */
.css-mnn31.Mui-disabled {
  background-color: #fff !important;
}

.css-zrqpq1.Mui-disabled::before {
  border-bottom-style: none;
}

.css-zrqpq1::before {
  border-bottom: #1976d2 !important;
}

.css-1ontqvh {
  margin-top: 35px;
}

span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
  font-family: 'Poppins';
}

.css-6xugel> :nth-of-type(1) {
  font-size: 27px;
}

/* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
  font-size: 31px;
} */
.search_feild .css-1iz4iln {
  fill: rgb(25, 118, 210) !important;
}

.chat_link {
  display: flex;
  align-items: center;
}

.link-with-icon {
  flex: 1;
}

/* .editLink {
  margin-left: 150px; 
} */

.menu_list button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.css-1ujsas3 {
  margin-top: -9px;
}

.search_feild svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
  fill: rgb(25, 118, 210) !important;
}

.css-1hskriy {
  width: 250px;
  height: 100vh;
}

.css-6xugel> :nth-of-type(1) {
  font-size: 27px;
}

h2#modal-modal-title {
  font-family: 'Poppins';
  font-size: 28px;
}


.icn .css-vubbuv {
  fill: grey !important;
}


.tab .MuiBox-root {
  margin-bottom: 3px;
}

.tab button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1q2h7u5 {
  color: black;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-bottom: 1px solid white;
  border-left: 1px solid grey;
  font-family: Poppins;
}

.tab {
  margin-left: 34px;
  margin-right: 0px;
}

.tab .active-tab {
  border-bottom: 2px solid white;
}

.grid-box {
  margin-left: -23px !important;
  margin-right: -27px !important;
}

.tab {
  color: #000;
  background-color: #fff;
}

/* .tab.active-tab {
  color: #fff; 
  background-color: rgb(25, 118, 210); 
} */

.tab .Mui-selected {
  color: #fff !important;
  background-color: rgb(25, 118, 210);
}

/* .tab button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary{
  border: 1px solid rgb(25, 118, 210);
} */
.skelton p {
  margin-bottom: 15px;
}

.skelton .points {
  margin-left: 22px;
}

.skelton .divide {
  border-bottom: 1px solid grey;
  margin-bottom: 10px;
}

.handleIcon {
  margin-left: -70px;
  margin-right: 15px;
}

.tabBorder {
  border: 1px solid grey;
  margin-top: -4px;
}

.dltIcon {
  margin-right: 20px;
}

ul.inner-li {
  margin-left: 15px;
  margin-top: 21px;
}

.inner-li a {
  font-size: 14px !important;
  margin-top: -20px;
  margin-left: 11px;
}

.chat_heading .catgry-name {
  margin-top: 0px;
  font-size: 16px;
  color: #808080;
}

.Add-Icon {
  margin-left: 20px;
  margin-top: 10px;
}

.contain-text {
  display: flex;
  padding-left: 40px;
  padding-top: 20px;
}

.left-column {
  /* padding-right: 90px; */
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.Button-modal .MuiBox-root.css-0 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 850px;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
}

.language_head1 {
  margin-top: 35px;
}

.right-column2 {
  padding-left: 34px;
}

.right-column1 {
  padding-left: 75px;
}

/* .right-column {
  padding-left: 96px;
} */
.tab-buttons button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium {
  border-radius: 0px;
  background-color: transparent;
  color: #1976d2;
  border: 1px solid rgba(25, 118, 210, 0.5);
  box-shadow: none;
}

.tab-buttons button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium:focus {
  border: 1px solid rgba(25, 118, 210, 0.5)
}

.tab-buttons button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium {
  border-radius: 0px;
  background-color: transparent;
  color: #1976d2;
  border: none;
  box-shadow: none;
}

.tab-buttons button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium:focus {
  border: 1px solid rgba(25, 118, 210, 0.5);
}


.tab-buttons {
  border-bottom: 1px solid rgba(25, 118, 210, 0.5);
  margin-bottom: 10px;
}

.catgry p.MuiTypography-root.MuiTypography-body1.catgry-name.css-ahj2mt-MuiTypography-root {
  color: #000;
  cursor: pointer;
  font-size: 20px;
  /* font-weight: 700; */
  text-decoration: none;
  transition: all 1s ease;
  width: 100%;
  word-break: break-word !important;
  margin-left: 0px;
  margin-top: 10px;
}

.cipboard_div svg {
  cursor: pointer;
}

/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  border: 2px solid #1976d2!important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:hover {
  border: 1px solid #1976d2!important;
} */

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-animated.cat-heads {
  margin-right: -300px;
  color: #000;
  margin-left: -300px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.cat-select {
  border: 2px solid #1976d2;
  width: 165px;
}

/* .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.cat-select :hover {
  border: 1px solid #1976d2!important;
} */

.search_box1 {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: center;
  gap: 154px;
}

.cat-heads1 {
  margin-right: -150px !important;
  color: #000;
  margin-left: -85px;
}

.ellipsis {
  /* overflow: 'hidden'; */
  text-overflow: 'ellipsis';
  white-space: 'nowrap';
}

.label_input .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 32px;
  width: 300px;
}

.MuiStack-root.css-96myqx-MuiStack-rootbutton.remove-userfield {
  margin-right: 370px;
}

button.remove-userfield {
  border: 1px solid #c62828;
}

button.remove-userfield:hover {
  border: 1px solid #c62828;
  background-color: transparent !important;
  color: #c62828;
}

fieldset.MuiOutlinedInput-notchedOutline {
  display: none;
}

/* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  width: 39px;
  background-color: #1976d2;
  height: 57px;
  margin-right: -5px;
  margin-top: -17px;
  fill: #fff;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.cat-select svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon:focus {
  width: 39px;
  background-color: #1976d2;
  height: 57px;
  margin-right: -5px;
  margin-top: -17px;
  fill: #fff;
} */

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.cats-select {
  width: 300px;
  border: 1px solid #1976d2;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.user-select {
  border: 1px solid #1976d2;
}

.quarterly_buttons {
  float: right;
}

.language_dropdown_info {
  border: 2px solid #1976d2;
}

.icn svg {
  fill: grey !important
}


/* new css */

#tags-filled {

  min-width: 589px;
  border: 0px !important;
  padding: 13px;
  font-size: 16px;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;

}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 10px !important;
}


.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiAutocomplete-inputRoot.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root,
.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root,
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiAutocomplete-inputRoot.css-1bp1ao6,
.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.css-feqhe6 {

  border: 1px solid #1976d2 !important;

}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  border: 1px solid #1976d2 !important;
  width: 100%;
  min-width: 589px;
  padding: 0px;
  font-size: 16px;
  border-radius: 4px;
  font-family: Poppins;
}

#tags-filled {
  min-width: auto !important;
  border: 0px !important;
  padding: 13px;
  font-size: 16px;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  margin-bottom: 8px !important;
}

.label_input .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 0 !important;
}

#text-fields {
  border: 1px solid #1976d2 !important;
  width: 100%;
  min-width: 737px;
  padding: 13px;
  font-size: 16px;
  border-radius: 4px;
  font-family: Poppins;
  min-height: 50px;
  height: 100% !important;
}

.copyTemplate {
  margin: 0;
}

.css-i4bv87-MuiSvgIcon-root {
  fill: currentColor !important;
}

@media (max-width:600px) {
  .main-user {
    font-size: 13.5px;
  }

  .main-user .text i p.user-name {
    margin-right: 20px;
    min-width: 250px;
  }
}

.template-owner-details {
  position: relative;
  bottom: 24px;
  left: 100px
}

@media (max-width:530px) {
  .template-owner-details {
    position: unset !important;
    margin-top: 10px;
  }
}

@media (max-width:720px) {
  #text-fields {
    min-width: auto;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.TitleDes {
  height: 110px;
  /* height: 150px; */
}

#share-owner-icon {
  fill: grey !important;
}

#templates-pagination div p,
#templates-pagination div div {
  display: none;
}

#templates-pagination .MuiTablePagination-displayedRows,
#templates-pagination .MuiTablePagination-actions,
#templates-pagination .MuiTablePagination-spacer {
  display: block !important;
}

.output_word {
  display: flex;
  align-items: center;
}
.label_input1{
  gap: 0px
}

.loader-container {
  opacity: 1;
  visibility: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.bouncing-dots {
  display: flex;
  justify-content: space-between;
  width: 60px;
}

.dot {
  width: 15px;
  height: 15px;
  background-color: rgb(25, 118, 210);
  border-radius: 50%;
  animation: bounce 1.5s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes bounce {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-20px);
  }
}
#loader-main{
    position: fixed;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    color: rgb(255, 255, 255);
    z-index: 1201;
    opacity: 1;
    visibility: visible;
}
.prompt_div {
  cursor: grab;
  /* Add other styles as needed */
}

.prompt_div.dragging {
  cursor: grabbing;
}


.flexDrag {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  gap: 10px; /* Optional: add some space between the items */
}



.drag_handle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: grab;
}

.drag_handle div {
  width: 16px;
  height: 2px;
  background: #333;
  margin: 2px 0;
}
